import tippy from 'tippy.js';

tippy('[data-tooltips]', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  trigger: 'mouseenter focus',
  placement: 'bottom',
  interactive: true,
  allowHTML: true,
  theme: 'custom',
  animation: 'fade',
  appendTo: 'parent',
});
tippy('[data-tooltipUser]', {
  content(reference) {
    const id = reference.getAttribute('data-template');
    const template = document.getElementById(id);
    return template.innerHTML;
  },
  trigger: 'mouseenter focus',
  placement: 'bottom',
  interactive: true,
  allowHTML: true,
  theme: 'user',
  animation: 'fade',
  appendTo: 'parent',
});

tippy('[data-tooltipPos]', {
  content(reference) {
    const template = reference.querySelector('.info-tooltip');
    return template.innerHTML;
  },
  trigger: 'mouseenter focus',
  placement: 'top',
  allowHTML: true,
  theme: 'grey',
  animation: 'fade',
});

tippy('[data-tooltipDesc]', {
  content(reference) {
    const template = reference.querySelector('.description');
    return template.innerHTML;
  },
  trigger: 'mouseenter focus',
  placement: 'top',
  interactive: true,
  allowHTML: true,
  theme: 'grey-desc',
  animation: 'fade',
});
